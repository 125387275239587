import React, { ChangeEvent, forwardRef, ReactElement, useImperativeHandle, useRef } from 'react';

import styles from './index.module.scss';

const UploadAction = forwardRef(
	(
		props: {
			children: ReactElement;
			multiple?: boolean;
			capture?: boolean | 'user' | 'environment' | undefined;
			accept?: string;
			onChange: (e: ChangeEvent<HTMLInputElement>) => any;
		},
		ref: any
	) => {
		const uploadRef = useRef<HTMLInputElement | null>(null);
		const openUploader = () => {
			const uploaderEl = uploadRef.current! as HTMLInputElement;
			uploaderEl.click();
		};
		useImperativeHandle(ref, () => ({
			clear: () => ((uploadRef.current as HTMLInputElement).value = '')
		}));

		return (
			<>
				<input
					ref={uploadRef}
					multiple={props?.multiple}
					capture={props?.capture ?? false}
					className={styles.uploadUploader}
					type="file"
					accept={props?.accept ?? 'image/*'}
					onChange={(e) => props.onChange(e)}
				/>
				{React.cloneElement(props.children, {
					...props.children.props,
					onClick: () => openUploader()
				})}
			</>
		);
	}
);

export default UploadAction;
