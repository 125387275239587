import classNames from 'classnames';
import React, {
	ChangeEvent,
	forwardRef,
	ReactElement,
	useEffect,
	useImperativeHandle,
	useRef,
	useState
} from 'react';
import { useSelector } from 'react-redux';
import { IFileListItem, ypMediaUpload } from 'yp-media-upload-service';
import { ypMediaUploadUpdate } from 'yp-media-upload-update-service';

import { AppState } from '$lib/model/wrapper/common';
import { YP_ENV } from '$lib/utils/env';
import { fileToDataUrl } from '$lib/utils/tools/file';

import { toast } from '../toast';
import TouchFeedBack from '../touch-feed-back';
import styles from './index.module.scss';
import UploadAction from './UploadAction';
import UploadList from './UploadList';

export type IFile = {
	type: number;
	url: string;
	dataUrl: string;
	file?: Blob;
	percent?: number;
	status: 'pending' | 'success' | 'error';
	uid: string;
	relative_url?: string;
	resourceId?: string;
};
export type IUploadProps = {
	uploadListItemClass?: string;
	className?: string;
	disabled?: boolean;
	fileList?: IFile[] | string[];
	children?: ReactElement;
	accept?: string;
	capture?: boolean | 'user' | 'environment' | undefined;
	multiple?: boolean;
	limit?: number;
	data?: Record<string, any>;
	headers?: Record<string, any>;
	customList?: boolean;
	onError?: (arg: { file: File; type: string }) => any;
	onRemove?: (file: IFile, fileList: IFile[], index: number) => any;
	onChange?: (file: IFile, fileList: IFile[]) => any;
	onSuccess?: (file: IFile) => any;
	beforeUpload?: (file: IFile, fileList: IFile[]) => any;
	onBatchStart?: (fileList: IFile[]) => any;
	url?: string,
};
const Upload = forwardRef((props: IUploadProps, ref) => {
	const { headers: config_headers } = useSelector((state: AppState) => state.configModel);
	const { token } = useSelector((state: AppState) => state.userModel);
	/** 是否来自水印相机 */
	const isWaterMark = ['SYXJ'].includes(config_headers?.business || '');

	const {
		url,
		children,
		limit = 10,
		fileList,
		multiple,
		headers,
		data,
		disabled,
		className,
		accept,
		capture,
		onError,
		onRemove,
		onSuccess,
		onBatchStart,
		beforeUpload,
		customList,
		onChange,
		uploadListItemClass
	} = props;
	const [_fileList, setFileList] = useState<IFile[]>([]);
	const fileListRef = useRef<IFile[]>([]);
	const actionRef = useRef<any>(null);
	const deleteList = useRef<(number | string)[]>([]);
	const refEl = useRef<any>();

	useImperativeHandle(ref, () => ({
		setUploadFileList: (v: IFile[]) => {
			setFileList(v);
		},
		ref: refEl
	}));

	useEffect(() => {
		if (fileList) {
			const timestamp = Date.now();
			const tFileList = fileList.map((each, index: number) => {
				if (Object.prototype.toString.call(each) === '[object Object]') {
					return each;
				} else {
					return {
						type: 0,
						file: '',
						percent: 100,
						status: 'success',
						dataUrl: '',
						url: each,
						// eslint-disable-next-line no-param-reassign
						uid: `upload-${timestamp + index}-${++index}`
					};
				}
			}) as IFile[];
			setFileList(tFileList);
		}
	}, [fileList]);

	const updateFileInfo = (currentFile: IFile, newFileList: IFile[]) => {
		const tFileList = newFileList.slice(0, limit ?? newFileList.length);
		fileListRef.current = tFileList;
		onChange?.(currentFile, tFileList);
		setFileList(tFileList);
	};

	const onRemoveHandler = (file: IFile, index: number) => {
		deleteList.current.push(file.uid);
		const filterFileList = _fileList.filter((_, i) => i !== index);
		updateFileInfo(file, filterFileList);
		onRemove?.(file, filterFileList, index);
	};

	const changeFile = async (e: ChangeEvent<HTMLInputElement>) => {
		const files = e.target.files ?? [];
		const timestamp = Date.now();
		let tFileList = [];
		tFileList = Array.from(files).map((each, index) => {
			return {
				type: each.type.indexOf('image') > -1 ? 0 : 1,
				file: each,
				percent: 0,
				status: 'pending' as IFile['status'],
				dataUrl: '',
				url: '',
				relative_url: '',
				resourceId: '',
				// eslint-disable-next-line no-param-reassign
				uid: `upload-${timestamp + index}-${++index}`
			};
		});
		/** 若只允许上传图片 */
		if (accept === 'image/*' || !accept) {
			const temp = tFileList.filter((f) => !f?.type);
			if (
				tFileList?.length !== temp?.length ||
				(tFileList?.length === temp?.length && !tFileList?.length)
			) {
				toast.show('仅支持图片上传');
			}
			tFileList = temp;
		}

		// 初始化File后执行onBatchStart
		await onBatchStart?.(tFileList);
		const newFileList = (_fileList.length ? _fileList : []).concat(tFileList).slice(0, limit);

		for (let file of newFileList) {
			if (!file?.dataUrl && !file?.url) {
				const dataUrl = await fileToDataUrl(file?.file!);
				file.dataUrl = dataUrl;
				updateFileInfo(file, newFileList);
				await beforeUpload?.(file, newFileList);
			}
		}
		actionRef.current?.clear?.();
		postFiles(newFileList);
	};

	const updateCon = (shouldUploadList: IFile[]) => {
		const ypMediaUploadCon = {
			headerConfig: {
				// 请求头header 参数
				appId: 101,
				sdkVersion: '1.0.1',
				platform: 'h5',
				appVersion: '1.2.3',
				system: 'android',
				systemVersion: '14.1.1',
				env: YP_ENV === 'develop' ? 'dev' : (YP_ENV as any),
				timeout: 600,
				...headers
			},
			paramConfig: {
				// 请求 body 参数
				entryId: 0,
				fileList: shouldUploadList as unknown as IFileListItem[],
				...data
			}
		};

		const ypMediaUploadUpdateCon = {
			headerConfig: {
				// 请求头header 参数
				appId: 101,
				system: 'android',
				env: YP_ENV === 'develop' ? 'dev' : (YP_ENV as any),
				timeout: 600,
				token,
				...headers,
				...config_headers
			},
			paramConfig: {
				// 请求 body 参数
				fileList: shouldUploadList as unknown as IFileListItem[],
				entryId: 0,
				...data
			}
		};

		/** 非水印 走新逻辑 */
		if (isWaterMark) {
			return ypMediaUploadCon;
		} else {
			return ypMediaUploadUpdateCon;
		}
	};

	const postFiles = (fileList: IFile[]) => {
		const updateFileValue = (file: any) => {
			const tFileList = fileListRef.current
				.map((each) => {
					if (each.uid === file.uid) {
						return file;
					}
					return each;
				})
				.filter((each) => !deleteList.current?.find((uid) => uid == each.uid));

			updateFileInfo(file, tFileList);
		};

		const shouldUploadList = fileList.slice(0, fileList.length).filter((each) => !each.url);
		console.warn(shouldUploadList, '图片上传');
		if (!shouldUploadList?.length) return;

		const config: any = {
			...updateCon(shouldUploadList),
			onUploadProgress: (file: any) => {
				updateFileValue(file);
			},
			onSuccess: (file: any) => {
				updateFileValue(file);
				if (!deleteList.current?.find((uid) => uid == file.uid)) {
					onSuccess?.(file);
				}
			},
			onError: (file: any) => {
				updateFileValue(file);
				onError?.(file);
			},
			onRequestFail: (errorInfo: { error: boolean; message: string }) => {
				if (errorInfo?.error) toast.show(errorInfo?.message || '资源类型不支持');
				const tList = fileList.slice(0);
				for (const file of tList) {
					if (file?.resourceId) updateFileValue(file);
					else updateFileValue({ ...file, status: 'error' });
				}
			}
		};

		/** 非水印 走新逻辑 */
		if (isWaterMark) {
			ypMediaUpload(config);
		} else {
			ypMediaUploadUpdate({ ...config, portPath: url});
		}
	};

	const renderButtonHandler = () => {
		return (
			<UploadAction
				ref={actionRef}
				accept={accept}
				capture={capture}
				multiple={multiple}
				onChange={changeFile}
			>
				{children ?? (
					<div
						style={{ marginRight: 0 }}
						className={classNames(styles.uploadButton, uploadListItemClass)}
					>
						<TouchFeedBack activeClassName={styles.uploadButtonContainerTouch}>
							<div className={styles.uploadButtonContainer}>
								<div className={styles.horizontalLine} />
								<div className={styles.verticalLine} />
							</div>
						</TouchFeedBack>
					</div>
				)}
			</UploadAction>
		);
	};
	return (
		<div className={classNames(styles.upload, className)} ref={refEl}>
			{!customList && (
				<UploadList
					uploadListItemClass={uploadListItemClass}
					fileList={_fileList}
					onRemove={onRemoveHandler}
				/>
			)}
			{(limit && _fileList.length >= limit) || disabled ? <></> : renderButtonHandler()}
		</div>
	);
});

export default Upload;
