/**
 * @description: 将图片直接转化date URL字符串形式
 * @param {Blob} file
 * @param {function} callback
 * @return {void}
 */
export const fileToDataUrl = (file: Blob): Promise<any> => {
	const URL = window?.URL || window?.webkitURL;
	if (URL) {
		const url = window.URL.createObjectURL(file);
		return new Promise((r) => {
			r(url);
		});
	} else {
		const reader = new FileReader();
		reader.readAsDataURL(file);
		return new Promise((r, j) => {
			reader.onload = () => {
				r(reader.result);
			};
			reader.onerror = () => {
				j();
			};
		});
	}
};

/**
 * @description: base64转blob
 * @param {any} dataUrl
 * @param {string} type
 * @return {Blob}
 */
export function dataUrlToBlob(dataUrl: any, type?: string): Blob {
	const data = dataUrl.split(',')[1];
	const mimePattern = /^data:(.*?)(;base64)?,/;
	const mime = dataUrl.match(mimePattern)[1];
	const binStr = atob(data);
	const arr = new Uint8Array(binStr.length);

	for (let i = 0; i < binStr.length; i++) {
		arr[i] = binStr.charCodeAt(i);
	}
	return new Blob([arr], { type: type || mime });
}
