import classNames from 'classnames';
import has from 'lodash/has';
import { memo, useCallback } from 'react';
import { useSelector } from 'react-redux';

import IconButtonLoading from '$icons/IconButtonLoading';
import IconImageFailed from '$icons/IconImageFailed';
import IconUploadDelete from '$icons/IconUploadDelete';
import IconVideoFailed from '$icons/IconVideoFailed';
import IconVideoPlay from '$icons/IconVideoPlay';
import { AppState } from '$lib/model/wrapper/common';
import { PREVIEW_FAIL } from '$lib/utils/image_cdn';

import Viewer from '../viewer';
import styles from './index.module.scss';
import type { IFile } from './Upload';

interface IUploadList {
	fileList: IFile[];
	onRemove?: (file: IFile, index: number) => any;
	onPreview?: (file: IFile, index: number) => any;
	/** 图片展示样式  */
	uploadListItemClass?: string;
}

const UploadList = memo((props: IUploadList) => {
	const { fileList = [], onRemove, uploadListItemClass } = props;
	const { query: { top = 0, bottom = 0 } = {} } = useSelector(
		(state: AppState) => state.configModel
	);
	const renderProgress = useCallback(({ percent }: { percent?: number }) => {
		return (
			<div className={styles.progress}>
				{percent !== undefined ? (
					<div className={styles.progressRail}>
						<div className={styles.sliding} style={{ width: percent + '%' }} />
					</div>
				) : (
					<IconButtonLoading className={styles.progressLoading} />
				)}

				<div className={styles.progressText}>上传中</div>
			</div>
		);
	}, []);

	const renderError = useCallback((file: IFile) => {
		return (
			<>
				{file.type === 0 ? (
					<IconImageFailed className={styles.img} />
				) : (
					<IconVideoFailed className={styles.img} />
				)}
			</>
		);
	}, []);

	const preview = (index: number) => {
		const previewData = fileList.map((each) => ({
			url: each?.url ? each?.url : PREVIEW_FAIL,
			type: each.type === 0 ? 'image' : 'video'
		}));
		Viewer.Multi.show({
			files: previewData,
			defaultIndex: index,
			headerStyles: {
				paddingTop: `${top ?? 0}px`
			},
			bodyStyles: { paddingBottom: `${bottom ?? 0}px` }
		});
	};

	const getPoster = useCallback((url: string) => {
		if (url && url.indexOf('?') > -1) {
			return url + '&x-oss-process=video/snapshot,t_332,m_fast';
		} else {
			return url + '?x-oss-process=video/snapshot,t_322,m_fast';
		}
	}, []);

	const RenderVideo = useCallback(
		({ url, status }: { url: string; status: IFile['status'] }) => {
			const fullUrl = new RegExp(/^(https:).*/).test(url);
			const showVideoTag = new RegExp(/(blob\:)|(video)|(https:)/).test(url);
			return (
				<>
					{showVideoTag ? (
						!!fullUrl && <img className={styles.img} src={getPoster(url)} alt="" />
					) : (
						<img className={styles.img} src={url} alt="" />
					)}
					{status === 'success' && (
						<div className={styles.videoMask}>
							<IconVideoPlay className={styles.playIcon} />
						</div>
					)}
				</>
			);
		},
		[getPoster]
	);

	const RenderImage = useCallback(({ url }: { url: string }) => {
		return <img className={styles.img} src={url} alt="" />;
	}, []);

	return (
		<>
			{fileList.map((file, index) => {
				let tUrl = file?.dataUrl ?? '';

				if ((!tUrl || /(blob\:)|(video)/.test(tUrl)) && file?.url) {
					tUrl = file?.url?.replace(/^http:/, 'https:') ?? '';
				}
				return (
					<div
						key={`upload-list-item-${index}`}
						className={classNames(
							styles.uploadListItem,
							{ 'mr-24': (index + 1) % 4 !== 0 },
							uploadListItemClass
						)}
					>
						<div
							className={classNames(styles.uploadListItemBlock, uploadListItemClass)}
							onClick={() => preview(index)}
						>
							{file.status === 'pending' &&
								renderProgress(has(file, 'percent') ? { percent: file.percent } : {})}
							{['pending', 'success'].includes(file.status) && (
								<>
									{file.type === 0 && RenderImage({ url: tUrl })}
									{file.type === 1 && RenderVideo({ url: tUrl, status: file.status })}
								</>
							)}

							{file.status === 'error' && renderError(file)}
						</div>
						<IconUploadDelete
							className={styles.remove}
							type="upload-remove-icon"
							onClick={() => onRemove?.(file, index)}
						/>
					</div>
				);
			})}
		</>
	);
});

export default UploadList;
